import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@liveblocks/react-ui/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@uppy/core/dist/style.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@uppy/dashboard/dist/style.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@uppy/screen-capture/dist/style.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@uppy/webcam/dist/style.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@xyflow/react/dist/style.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/plyr/dist/plyr.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/onstage/web/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/onstage/web/src/components/legacy/Navigator/Navigator.global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/onstage/web/src/styles/globals.css");
